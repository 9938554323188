<template>
  <SubviewCard :class="'my-4'">
    <v-layout align-start class="mt-6">
      <LabelTextField
        :value.sync="patient.repository_address"
        label="Adres repozytorium"
        placeholder="Wpisz adres repozytorium..."
        hideDetails
      >
        <InfoButton
          slot="header-items"
          text="Do dokumentacji indywidualnej wewnętrznej włącza się dokumentację udostępnioną przez pacjenta lub zamieszcza
się adres repozytorium, pod którym znajduje się dokumentacja udostępniona za pomocą środków komunikacji elektronicznej w rozumieniu art. 2 pkt 5 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną, lub załącza się cyfrowe odwzorowanie dokumentacji w postaci papierowej lub odnotowuje się zawarte w udostępnionej dokumentacji informacje, które są istotne dla procesu diagnostycznego, leczniczego, pielęgnacyjnego lub rehabilitacyjnego"
        ></InfoButton>
      </LabelTextField>
      <DefaultButton
        @click.prevent="edit"
        bgColor="expressive_green"
        class="my-0 ml-6 mr-4"
        primary
        >Zapisz
      </DefaultButton>
    </v-layout>
  </SubviewCard>
</template>

<script>
import PatientService from "@/services/patient.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

export default {
  mixins: [ProxyCRUDMixin],
  data() {
    return {
      patient: {},
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    LabelTextField: () => import("@/components/LabelTextField"),
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
  },
  methods: {
    fetch() {
      this.beforeRequest();
      PatientService.getPatient()
        .then((patient) => {
          this.patient = patient;
          this.reset();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    edit() {
      this.beforeRequest();
      PatientService.editPatient(this.patient)
        .then((patient) => {
          this.patient = patient;
          this.handleSuccess("Wprowadzone zmiany zostały zapisane");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
